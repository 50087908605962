import React, { Component } from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { getUserData } from "../../../store/ducks/currentUser/actions";
import { connect } from 'react-redux';
import { isDesktop } from 'react-device-detect';
import * as actions from "../../../store/ducks/christmas/actions";
import { Fade } from "react-awesome-reveal";
import MobileChristmasEvent from './MobileChristmasEvent';
import Swal from "sweetalert2";
export class ChristmasEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventEnd: false,
            qualificationStatus: false,
            buttonDisabled: false,
        };
    }

    componentDidMount() {
        if (this.props.currentUser.country_code !== 'MY') {
            return window.location.href = '/';
        }
        const today = new Date();
        const buttonExpiredDate = new Date(`2024-12-05 23:59:59`);
        if (today >= buttonExpiredDate) {
            this.setState({ buttonDisabled: true });
            if (this.checkedButtonRef) {
                this.checkedButtonRef.disabled = true
            }
        }

        const expired = new Date(`2024-12-31 00:00:00`);// winner list show date
        if (today >= expired) {
            this.setState({ eventEnd: true });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        const { t } = this.props;

        if (nextProps.claim_result !== this.props.claim_result && nextProps.claim_result !== undefined) {
            if (nextProps.claim_result.responseCode === 200 && nextProps.claim_result.msgType === "success") {
                if (nextProps.claim_result.data.eligible === false) {
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">' : '',
                        icon: 'success',
                        title: t('register.congratulation'),
                        html: t('christmas-event.congrats-msg'),
                        confirmButtonText: t('global.confirm'),
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container sucess',
                            icon: 'swal-success-icon',
                        } : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // this.setState({ buttonDisabled: true });
                        }
                    });
                } else {
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
                        icon: "error",
                        title: t("global.sorry"),
                        text: t('christmas-event.sorry-msg'),
                        showCancelButton: true,
                        confirmButtonText: t("norecord.deposit-now"),
                        cancelButtonText: t("global.confirm"),
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container failed',
                            icon: 'swal-sorry-icon'
                        } : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/deposit';
                        }
                    });
                }
            } else {
                return false;
            }
        }

        return true;

    }

    claimHandler = () => {
        // if (this.state.buttonDisabled) {
        //     return;
        // }

        let data = {};
        data.action = "click";
        data.member_id = this.props.currentUser.id
        this.props.funcApplyClaim(data)
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const tNc = this.imageImport(require.context("../../../assets/images/tnc", false, /\.(png|jpe?g|svg)$/));
        const banner = this.imageImport(require.context("../../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const prizeImg = this.imageImport(require.context("../../../assets/images/christmas-lucky-draw", false, /\.(png|jpe?g|svg|webp)$/));

        let imageLanguage = '';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn";
                break;
            case "ms":
                imageLanguage = "-bm"
                break;
            case "indon":
                imageLanguage = "-indon"
                break;
            default:
                imageLanguage = "";
                break;
        }


        return (
            <Auxiliary>
                {isDesktop ?
                    (
                        <section className='pl-section'>
                            <div className="banner-col">
                                <div className="banner-item">
                                    <img src={banner[`xmas_2024${imageLanguage}.webp`]} />
                                </div>
                            </div>

                            <div className='x-container'>
                                <div className='section-header-gray mb-3 '>
                                    <h2>{t('christmas-event.title')}</h2>
                                    <p>{t('christmas-event.subtitle')}</p>
                                    <p className='event-date'>{t('christmas-event.date')}</p>
                                </div>

                                {this.state.eventEnd ? (
                                    <>
                                        <div className='d-flex align-items-center justify-content-center mb-5 mt-5'>
                                            <span className='px-4'>
                                                <img className="w-75 h-auto object-fit-cover" src={prizeImg[`star.svg`]} alt="star icon" />
                                            </span>
                                            <div className='section-header-gray mb-0'>
                                                <h2>{t('christmas-event.lucky-winner')}</h2>
                                            </div>
                                            <span className='px-4'>
                                                <img className="w-75 h-auto object-fit-cover" src={prizeImg[`star.svg`]} alt="star icon" />
                                            </span>
                                        </div>
                                        <Fade duration={1000} triggerOnce>
                                            <div className='term-section christmas'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('christmas-event.rank')}</th>
                                                            <th>{t('leaderboard.prize')}</th>
                                                            <th>{t('leaderboard.winner')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t('christmas-event.1st-prize')}</td>
                                                            <td>{t('christmas-event.myr33000')}</td>
                                                            <td>d*a*a*p*nstorm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('christmas-event.2nd-prize')}</td>
                                                            <td>{t('christmas-event.macbook')}</td>
                                                            <td>M*g*g*n*85</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('christmas-event.3rd-prize')}</td>
                                                            <td>{t('christmas-event.iphone')}</td>
                                                            <td>s*h*a*d*owkt47</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('christmas-event.4th-more')}</td>
                                                            <td>{t('christmas-event.myr133')}</td>
                                                            <td>c*b*e*r*master<br/>
                                                                t*h*u*n*dr293<br/>
                                                                e*l*t*e*player<br/>
                                                                g*a*m*I*ngx92<br/>
                                                                f*o*t*s*t99<br/>
                                                                n*n*j*a*prime<br/>
                                                                p*x*o*e*hz34</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>


                                        </Fade>
                                    </>

                                ) : ("")}

                                <div className='section-header-gray mt-5'>
                                    <h3>{t('christmas-event.prize-to-won')}</h3>
                                    <p> {t('christmas-event.minimun-deposit', { attribute: process.env.REACT_APP_WEBSITE_TITLE })} </p>
                                </div>

                                <div >
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div >
                                            <img className='w-100 h-auto object-fit-cover' src={prizeImg[`prize-2${imageLanguage}.webp`]} alt="xmas lucky draw two" />
                                        </div>
                                        <div>
                                            <img className='w-100 h-auto object-fit-cover' src={prizeImg[`prize-1${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                                        </div>
                                        <div >
                                            <img className='w-100 h-auto object-fit-cover' src={prizeImg[`prize-3${imageLanguage}.webp`]} alt="xmas lucky draw three" />
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <img className='mw-100 h-auto object-fit-cover' src={prizeImg[`prize-4${imageLanguage}.webp`]} alt="xmas lucky draw four" />
                                    </div>

                                    <div className="d-flex align-items-center justify-content-center mt-5">
                                        {(localStorage.getItem("accessToken")) ?
                                            <button
                                                className="d-flex align-items-center justify-content-center btn-gradient-blue btn w-25"
                                                onClick={this.claimHandler}
                                                id="checked"
                                                disabled={this.state.buttonDisabled}
                                                ref={(ref) => (this.checkedButtonRef = ref)}
                                            >
                                                {t('christmas-event.check')}
                                            </button>
                                            :
                                            null
                                        }
                                    </div>
                                </div>

                                <div className='term-section mt-5'>
                                    <div className='term-container card-shadow'>
                                        <img src={tNc[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" />
                                        <div className='term-content'>
                                            <ol>
                                                <li>{t('christmas-event.tnc-1')}</li>
                                                <li>{t('christmas-event.tnc-2')}</li>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('christmas-event.1st-prize')}</th>
                                                            <th>{t('christmas-event.2nd-prize')}</th>
                                                            <th>{t('christmas-event.3rd-prize')}</th>
                                                            <th>{t('christmas-event.4th-more')}s</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t('christmas-event.myr33000')}</td>
                                                            <td>{t('christmas-event.macbook')} <small>{t('christmas-event.macbook-details')}</small></td>
                                                            <td>{t('christmas-event.iphone')}</td>
                                                            <td>{t('christmas-event.myr133')}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <li>{t('christmas-event.tnc-3')}</li>
                                                <li>{t('christmas-event.tnc-4', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                                                <li>{t('christmas-event.tnc-5')}</li>
                                                <li>{t('christmas-event.tnc-6', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                                                <li>{t('christmas-event.tnc-7', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                                                <li>{t('christmas-event.tnc-8', { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</li>
                                            </ol>
                                        </div>
                                        <img src={tNc["term-footer.png"]} alt="Terms Condition footer" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                    :
                    (
                        <MobileChristmasEvent
                            {...this.state}
                            {...this.props}
                            claimHandler={this.claimHandler}
                        />
                    )
                }

            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
        claim_result: state.christmas.claim_result,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        getUserData: (data) => dispatch(getUserData(data)),
        funcApplyClaim: (data) => dispatch(actions.claim(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ChristmasEvent));