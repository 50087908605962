import React, { Component } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import queryString from "query-string";
import axios from "axios";
import { deviceType, deviceDetect } from "react-device-detect";
import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import ReactLoading from "react-loading";

//Miscellaneous
import bgImg from "../../assets/images/dropdown/dropdown-bg.webp";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { numFormat, detectCountry } from "../../hoc/Shared/utility";

//Store
import { getGameVendorList } from "../../store/ducks/general/actions";
import { postLogOut } from "../../store/ducks/auth/actions";
import { getCountUnreadInboxAlert } from "../../store/ducks/inbox/actions";
import { getFeaturedPromotion } from "../../store/ducks/home/actions";
import {
  getTopProfile,
  socialMediaUserVerification,
} from "../../store/ducks/profile/actions";
import { postLogin, updateFingerPrint } from "../../store/ducks/auth/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";

import SlotHeader from "./Games/SlotHeader";
import GameHeader from "./Games/GameHeader";
import SportHeader from "./Games/SportHeader";
import ESportsHeader from "./Games/ESportsHeader";
import LiveCasinoHeader from "./Games/LiveCasinoHeader";
import P2PHeader from "./Games/P2PHeader";
import LotteryHeader from "./Games/LotteryHeader";
import * as CryptoJS from "crypto-js";
import moment from "moment";

let dynamicCountry = detectCountry(window.location.host);

class PageHeader extends Component {
  container = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      headerBg: false,
      login: localStorage.getItem("accessToken") ? true : false,
      profileDropdwon: false,
      alertCnt: 0,
      msgLoading: true,
      unreadMsgCnt: 0,
      promos: [],

      currentEvent: "christmas",
    };
  }

  componentDidMount() {
    const { t } = this.props;

    this.changeHeaderBg();
    window.addEventListener("scroll", this.changeHeaderBg);

    let params = {};
    // params.country_id = this.props.currentUser.data.country_id;
    this.props.getGameVendorList(params);

    if (this.state.login) {
      let data = {};
      data.lang_id = this.props.currentUser.data.language_id;
      this.props.getCountUnreadInboxAlert(data);

      this.props.getTopProfile();

      this.topProfileInterval = setInterval(() => {
        this.props.getTopProfile();
      }, 10000);
    } else {
      clearInterval(this.topProfileInterval);
    }

    let featuredPromosData = {};
    // featuredPromosData.country_id = this.props.currentUser.data.country_id;
    // featuredPromosData.lang_id = this.props.currentUser.data.language_id;
    this.props.getFeaturedPromotion(featuredPromosData);

    // Check which country and set the banner, annoucement to that specific country data
    let googleCredentials = queryString.parse(this.props.location.hash);
    let googleAccessToken = googleCredentials["access_token"];

    if (googleAccessToken) {
      //Call the Google API to get the user info
      axios
        .get(
          process.env.REACT_APP_GOOGLE_URL +
            "/oauth2/v1/userinfo?alt=json&access_token=" +
            googleAccessToken
        )
        .then((response) => {
          if (localStorage.getItem("accessToken") !== null || undefined || "") {
            sessionStorage.setItem(
              "google_user_info",
              JSON.stringify(response.data)
            );
            localStorage.setItem("google_user_info", response.data.email);

            const googleData = {};
            googleData.google_id = response.data.id;
            googleData.social_media_type = "google";
            googleData.google_link_email = response.data.email;
            this.props.socialMediaUserVerification(googleData);
          } else {
            sessionStorage.setItem(
              "google_user_info",
              JSON.stringify(response.data)
            );
            // localStorage.setItem("google_user_info", response.data.email);

            axios
            .get(
              'https://api6.my-ip.io/v2/ip.json'
            )
            .then((response_ip) => {
              if(response_ip.data){

                axios
                .get(
                  'https://api4.my-ip.io/v2/ip.json'
                )
                .then((responseIPV4) => {
                  if(responseIPV4.data){

                  const googleData = {};
                  googleData.google_id = response.data.id;
                  googleData.device_type = deviceType;
                  googleData.ipv6 = response_ip.data.ip;
                  googleData.ipv4 = responseIPV4.data.ip;
                  let params = this.encryptionAuth(googleData);

                  this.props.postLogin(params);
                  
                }
              }).catch((err) => {
                const googleData = {};
                googleData.google_id = response.data.id;
                googleData.device_type = deviceType;
                let params = this.encryptionAuth(googleData);

                this.props.postLogin(params);
              });
            }
            }).catch((err) => {
               //Get the IPV4
                axios
                .get(
                  'https://api4.my-ip.io/v2/ip.json'
                )
                .then((responseIPV4) => {
                  if(responseIPV4.data){
                    const googleData = {};
                    googleData.google_id = response.data.id;
                    googleData.device_type = deviceType;
                    googleData.ipv4 = responseIPV4.data.ip;
                    let params = this.encryptionAuth(googleData);

                    this.props.postLogin(params);
                  }
                }).catch((err) => {
                    const googleData = {};
                    googleData.google_id = response.data.id;
                    googleData.device_type = deviceType;
                    let params = this.encryptionAuth(googleData);

                    this.props.postLogin(params);
                });

              })
          }
        })
        .catch((err) => {
          Swal.fire({
            iconHtml: !isDesktop
              ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">'
              : "",
            title: t("global.failed"),
            // title: t("page-header.error-google-login"),
            text: err,
            icon: "error",
            confirmButtonText: t("global.ok"),
            customClass: !isDesktop
              ? {
                  container: "swal-mobile-container failed",
                  icon: "swal-failed-icon",
                }
              : [],
          });
        });
    }

    if (this.props.location.search) {
      let params = queryString.parse(this.props.location.search);
      if (
        params["id"] !== undefined &&
        params["hash"] !== undefined &&
        params["auth_date"] !== undefined &&
        localStorage.getItem("accessToken") !== null
      ) {
        const telegramData = {};
        telegramData.telegram_id = params["id"];
        telegramData.social_media_type = "telegram";
        this.props.socialMediaUserVerification(telegramData);
      }

      // Telegram Credentails
      if (
        params["id"] !== undefined &&
        params["hash"] !== undefined &&
        params["auth_date"] !== undefined &&
        localStorage.getItem("accessToken") === null
      ) {
        sessionStorage.setItem("telegram_user_info", JSON.stringify(params));

        axios
        .get(
          'https://api6.my-ip.io/v2/ip.json'
        )
        .then((response) => {
          if(response.data){

            axios
            .get(
              'https://api4.my-ip.io/v2/ip.json'
            )
            .then((responseIPV4) => {
              if(responseIPV4.data){

                const telegramData = {};
                telegramData.telegram_id = params["id"];
                telegramData.device_type = deviceType;
                telegramData.ipv6 = response.data.ip;
                telegramData.ipv4 = responseIPV4.data.ip;
                let data = this.encryptionAuth(telegramData);

                this.props.postLogin(data);
              
            }
          }).catch((err) => {
            const telegramData = {};
            telegramData.telegram_id = params["id"];
            telegramData.device_type = deviceType;
            let data = this.encryptionAuth(telegramData);

            this.props.postLogin(data);
          });

            
          }
        }).catch((err) => {
           //Get the IPV4
          axios
          .get(
            'https://api4.my-ip.io/v2/ip.json'
          )
          .then((responseIPV4) => {
            if(responseIPV4.data){
              const telegramData = {};
                telegramData.telegram_id = params["id"];
                telegramData.device_type = deviceType;
                telegramData.ipv4 = responseIPV4.data.ip;
                let data = this.encryptionAuth(telegramData);

                this.props.postLogin(data);
            }
          }).catch((err) => {
            const telegramData = {};
            telegramData.telegram_id = params["id"];
            telegramData.device_type = deviceType;
            let data = this.encryptionAuth(telegramData);

            this.props.postLogin(data);
          });
        });

      }

      // Landing Page choose country
      if (params["country_id"] !== undefined || null || "") {
        // need to check when this condition will be triggered

        const data = {};
        data.country_code = params["country_code"];
        data.country_id = parseInt(params["country_id"]);
        data.language_code = params["language_code"];
        data.language_id = parseInt(params["language_id"]);
        data.time_zone = this.state.timezone;
        data.member_group_id = this.props.currentUser.member_group_id;

        // here
        this.props.getUserData(data);

        const showBannerData = {};
        // showBannerData.country_id = parseInt(params["country_id"]);

        const showAnnouncementData = {};
        // showAnnouncementData.country_id = parseInt(params["country_id"]);
        // showAnnouncementData.member_group_id = this.props.currentUser.member_group_id;
        // showAnnouncementData.lang_id = parseInt(params["language_id"]);

        this.props.showBanner(showBannerData);
        this.props.showAnnouncement(showAnnouncementData);

        // Push to the home page
        this.props.history.push("/");
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { t } = this.props;

    if (nextProps.login_data !== this.props.login_data) {
      if (nextProps.login_data.responseCode === 200) {
        Swal.close();
        this.setState({ isLoading: false });

        let forceReload = false;
        if (
          sessionStorage.getItem("telegram_user_info") ||
          sessionStorage.getItem("google_user_info")
        ) {
          forceReload = true;
        }
        sessionStorage.removeItem("telegram_user_info");
        sessionStorage.removeItem("google_user_info");

        let loginData = nextProps.login_data.data;
        const loggedData = {};
        loggedData.id = loginData.id;
        loggedData.username = loginData.username;
        loggedData.name = loginData.name;
        loggedData.user_group = loginData.user_group;
        loggedData.user_group_level = loginData.user_group_level;
        loggedData.country_id = loginData.country_id;
        loggedData.country_code = loginData.country_code;
        loggedData.currency = loginData.currency;
        loggedData.language_id = loginData.language_id;
        loggedData.language_code = loginData.language_code;
        loggedData.member_group_id = loginData.group_id;
        loggedData.group_id = loginData.group_id;
        loggedData.contact_no = loginData.contact_no;
        loggedData.email = loginData.email;
        loggedData.balance = loginData.balance;
        loggedData.register_date_time = loginData.created_at;
        loggedData.time_zone = loginData.time_zone;
        this.props.getUserData(loggedData);

        localStorage.setItem("accessToken", loginData.access_token);
        localStorage.setItem(
          "lang",
          loginData.language_code.replace("-", "_").toString().toLowerCase() ||
            "en"
        );

        // reset vip details page if diff country id
        localStorage.removeItem('vipDetails');

        // Initialize an agent at application startup.
        const fpPromise = FingerprintJS.load();

        // Get the visitor identifier when you need it.
        fpPromise
          .then((fp) => fp.get())
          .then((result) => {
            let fingerPrintData = {};
            fingerPrintData.member_id = loginData.id;
            fingerPrintData.browser = result.components.vendorFlavors.value[0];
            fingerPrintData.browser_version = result.browserVersion;
            fingerPrintData.operating_system = result.components.platform.value;
            fingerPrintData.operating_system_version = result.osVersion;
            fingerPrintData.finger_print_id = result.visitorId;
            // fingerPrintData.finger_print_response = JSON.stringify(result);
           //Get the IPV6
           axios
           .get(
             'https://api6.my-ip.io/v2/ip.json'
           )
           .then((response) => {
             if(response.data){

               //Get the IPV4
               axios
               .get(
                 'https://api4.my-ip.io/v2/ip.json'
               )
               .then((responseIPV4) => {
                 if(responseIPV4.data){
                   fingerPrintData.ip_address = responseIPV4.data.ip;
                   fingerPrintData.ip_address_v6 = response.data.ip;
                 }

                 if(!isDesktop){
                   let deviceModel;
                   deviceModel = deviceDetect();
       
                   fingerPrintData.device_model = deviceModel.vendor;
                   fingerPrintData.device_model_code = deviceModel.model;
                 }

                 this.props.updateFingerPrint(fingerPrintData);
               }).catch((err) => {
                   fingerPrintData.ip_address_v6 = response.data.ip;

                   if(!isDesktop){
                     let deviceModel;
                     deviceModel = deviceDetect();
         
                     fingerPrintData.device_model = deviceModel.vendor;
                     fingerPrintData.device_model_code = deviceModel.model;
                   }
 
                   this.props.updateFingerPrint(fingerPrintData);
               });
               
             }
           }).catch((err) => {
               //Get the IPV4
               axios
               .get(
                 'https://api4.my-ip.io/v2/ip.json'
               )
               .then((responseIPV4) => {
                 if(responseIPV4.data){
                   fingerPrintData.ip_address = responseIPV4.data.ip;
                 }

                 if(!isDesktop){
                   let deviceModel;
                   deviceModel = deviceDetect();
       
                   fingerPrintData.device_model = deviceModel.vendor;
                   fingerPrintData.device_model_code = deviceModel.model;
                 }

                 this.props.updateFingerPrint(fingerPrintData);
               }).catch((err) => {
                 // const data = {};
                 // data.username = this.state.formFilter.username;
                 // data.password = this.state.formFilter.password;
                 // data.device_type = deviceType;
                 // let params = this.encryptionAuth(data);

                 // //Call the login api
                 // this.props.postLogin(params);
               });
           });
   
            // if(!isDesktop){
            //   let deviceModel;
            //   deviceModel = deviceDetect();
  
            //   fingerPrintData.device_model = deviceModel.vendor;
            //   fingerPrintData.device_model_code = deviceModel.model;
            // }

            // this.props.updateFingerPrint(fingerPrintData);
          });

        if (isDesktop) {
          this.props.history.push("/");
          if (forceReload) {
            // fix weird bug where non-myr acc login with social media at myr-domain refuse to set member's country id correctly
            window.location.reload();
          }
        } else {
          this.props.history.push(`/online-sports-games-${dynamicCountry.label}`);
          if (forceReload) {
            // fix weird bug where non-myr acc login with social media at myr-domain refuse to set member's country id correctly
            window.location.reload(); // this section not triggering at mobile view
          }
        }

        this.setState({
          username: "",
          password: "",
          openModal: false,
          login: true,
        });

        this.props.getTopProfile();

        clearInterval(this.topProfileInterval); // clear existing interval first just in case

        this.topProfileInterval = setInterval(() => {
          this.props.getTopProfile();
        }, 10000);
      } else if (nextProps.login_data.responseCode === 422) {
        this.setState({
          isLoading: false,
          errorMsg: nextProps.login_data.message.input_error || [],
          formError: true,
        });
        Swal.fire({
          iconHtml: !isDesktop
            ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">'
            : "",
          title: t("global.failed"),
          text: nextProps.login_data.message,
          icon: nextProps.login_data.msgType,
          confirmButtonText: t("global.ok"),
          customClass: !isDesktop
            ? {
                container: "swal-mobile-container failed",
                icon: "swal-failed-icon",
              }
            : [],
        });
      } else {
        if (
          nextProps.login_data.data.message[0] ===
          "User not exist in the system!"
        ) {
          this.setState({ isLoading: false });
          Swal.close();
          window.location.href = "/registerSocialMedia";
        } else {
          this.setState({ isLoading: false });
          Swal.fire({
            iconHtml: !isDesktop
              ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">'
              : "",
            title: t("global.failed"),
            text: nextProps.login_data.data.message[0],
            icon: nextProps.login_data.data.msgType,
            confirmButtonText: t("global.ok"),
            customClass: !isDesktop
              ? {
                  container: "swal-mobile-container failed",
                  icon: "swal-failed-icon",
                }
              : [],
          });
        }
      }
    }

    if (nextProps.logout_data !== this.props.logout_data) {
      if (nextProps.logout_data.responseCode === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("google_user_info");
        sessionStorage.removeItem("google_user_info");
        localStorage.removeItem('showAngbao');
        // localStorage.removeItem('changeCountry');
        // localStorage.removeItem('lang');
        localStorage.clear();

        window.location.reload();
      } else {
        return false;
      }
    }

    if (
      nextProps.unreadMessagesCnt !== undefined &&
      nextProps.unreadMessagesCnt !== this.props.unreadMessagesCnt
    ) {
      if (
        nextProps.unreadMessagesCnt.responseCode === 200 &&
        nextProps.unreadMessagesCnt.msgType === "success"
      ) {
        let unreadCnt = nextProps.unreadMessagesCnt.data.totalCount;
        unreadCnt = unreadCnt >= 99 ? 99 : unreadCnt;
        this.setState({
          msgLoading: false,
          unreadMsgCnt: unreadCnt,
        });
      }
    }

    if (
      nextProps.featured_promos_data !== undefined &&
      nextProps.featured_promos_data !== this.props.featured_promos_data
    ) {
      if (
        nextProps.featured_promos_data.responseCode === 200 &&
        nextProps.featured_promos_data.msgType === "success"
      ) {
        let featuredPromosData = nextProps.featured_promos_data.data;

        if (featuredPromosData.length !== 0) {
          this.setState({
            promos: nextProps.featured_promos_data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    }

    if (
      nextProps.social_media_user_verification_result !==
      this.props.social_media_user_verification_result
    ) {
      if (
        nextProps.social_media_user_verification_result.responseCode === 200
      ) {
        Swal.fire({
          iconHtml: !isDesktop
            ? '<img src="https://api.iconify.design/mdi:check.svg?color=%23ffffff">'
            : "",
          icon: "success",
          title: t("global.success"),
          html: t("global.ok"),
          showConfirmButton: true,
          timer: 2000,
          customClass: !isDesktop
            ? {
                container: "swal-mobile-container sucess",
                icon: "swal-success-icon",
              }
            : [],
        }).then((result) => {
          // if(result.isConfirmed){
          window.location.href = "/personal-information";
          // }
        });
      } else if (
        nextProps.social_media_user_verification_result.responseCode === 422
      ) {
        this.setState({
          loading: false,
          touched: true,
          error_message:
            nextProps.social_media_user_verification_result.message,
        });

        Swal.close();
      } else {
        this.setState({ loading: false });
        Swal.fire({
          iconHtml: !isDesktop
            ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">'
            : "",
          title: t("global.failed"),
          text: nextProps.social_media_user_verification_result.data.message[0],
          icon: nextProps.social_media_user_verification_result.data.msgType,
          confirmButtonText: t("global.ok"),
          customClass: !isDesktop
            ? {
                container: "swal-mobile-container failed",
                icon: "swal-failed-icon",
              }
            : [],
        });
      }
    }

    return true;
  }

  changeHeaderBg = () => {
    if (window.scrollY >= 66) {
      this.setState({ headerBg: true });
    } else {
      this.setState({ headerBg: false });
    }
  };

  encryptionAuth = (data) => {
    data.trxTime = moment().unix();
    let toHashStr = "";
    let hashStr = "";
    let paramsKey = [];
    for (const key in data) {
      if (!paramsKey.includes(key)) {
        paramsKey.push(key);
      }
    }

    paramsKey.sort();
    paramsKey.forEach((val) => {
        let x = data[val];
        if (x !== "") {
            if (val === "file") {
                // file required hashing params
                toHashStr += val + x.name + x.size;
            } else {
                toHashStr += val + x;
            }
        }
    });
    toHashStr += process.env.REACT_APP_HASH_SECRET;
    hashStr = CryptoJS.MD5(toHashStr).toString();
    data.trxHash = hashStr;

    return data;
} ;

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  handleSignOutHandler = () => {
    this.props.postLogOut();
  };

  render() {
    const { t } = this.props;
    const num_format = numFormat(this.props.currentUser.data.currency);
    const img = this.imageImport(
      require.context("../../assets/images", false, /\.(png|jpe?g|svg)$/)
    );
    const imgDropdown = this.imageImport(
      require.context(
        "../../assets/images/dropdown",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const icons = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    );
    const avatar = this.imageImport(
      require.context(
        "../../assets/images/profile/profile-icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    const coin = this.imageImport(
      require.context(
        "../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    const christmas = this.imageImport(
      require.context(
        "../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    // const promos = [
    //   {
    //     name: "50% Welcome Bonus",
    //     img: "promos1.png",
    //     link: "#/",
    //   },
    //   {
    //     name: "5% Daily Reload Bonus",
    //     img: "promos2.png",
    //     link: "#/",
    //   },
    //   {
    //     name: "1% Cash Rebate No VIP REQ",
    //     img: "promos3.png",
    //     link: "#/",
    //   },
    // ];

    let topProfileData = null;
    if (!this.state.loading) {
      topProfileData =
        this.props.topProfileData && this.props.topProfileData.data;
    }

    return (
      <Auxiliary>
        <nav className={this.state.headerBg ? "navbar scrolled" : "navbar"}>
          <div className="x-container-no-m">
            <NavLink to={{ pathname: "/" }} className="navbar-brand">
              {this.state.currentEvent === "merdeka" && this.props.currentUser.data.country_id !== 4 ? (
                <img src={img["logo-merdeka.png"]} />
              ) : this.state.currentEvent === "mooncake" && this.props.currentUser.data.country_id !== 4 ? (
                <img src={img["logo-mooncake.png"]} />
              ) : this.state.currentEvent === "halloween" ? (
                <img src={img["logo-halloween.png"]} />
              ) : this.state.currentEvent === "christmas" ? (
                <img src={img["logo-christmas.png"]} />
              ): this.state.currentEvent === "cny" ? (
                <img src={img["logo-cny.png"]} />
              ): this.state.currentEvent === "raya" ? (
                <img src={img["logo-raya.png"]} />
              ): this.state.currentEvent === "uefa" ? (
                <img src={img["logo-uefa.png"]} />
              ): this.state.currentEvent === "anni" ? (
                <img src={img["logo-anniversary.png"]} />
              ):
              (
                <img src={img["merchant-logo.png"]} />
              )}
            </NavLink>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/" }}
                  className="nav-link"
                  exact={true}
                  activeClassName="active"
                >
                  {t("profile-sidebar.home")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: `/online-live-games-${dynamicCountry.label}` }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {t("page-header.live-casino")}
                </NavLink>
                <div className="dropdown-menu-list">
                  <div className="x-container-no-m navbar-expand">
                    <div className="row g-2">
                      <LiveCasinoHeader imgDropdown={imgDropdown} />
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: `/online-sports-games-${dynamicCountry.label}` }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {t("page-header.sports")}
                </NavLink>
                <div
                  className="dropdown-menu-list sport-dropdown"
                  style={{ backgroundImage: `url(${bgImg})` }}
                >
                  <div className="x-container-no-m navbar-expand">
                    <div className="row g-2 justify-content-around">
                      <SportHeader imgDropdown={imgDropdown} />
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: `/e-sports` }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {t("page-header.esports")}
                </NavLink>
                <div 
                  className="dropdown-menu-list esports-dropdown"
                  style={{ backgroundImage: `url(${bgImg})` }}
                >
                  <div className="x-container-no-m navbar-expand">
                    <div className="row g-2">
                      <ESportsHeader imgDropdown={imgDropdown} />
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/slot" }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {t("page-header.slots")}
                </NavLink>
                <div className="dropdown-menu-list">
                  <div className="x-container-no-m navbar-expand">
                    <div className="row g-2">
                      <SlotHeader imgDropdown={imgDropdown} />
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/games" }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {t("page-header.games")}
                </NavLink>
                <div className="dropdown-menu-list game-dropdown">
                  <div className="x-container-no-m navbar-expand">
                    <div className="row g-2">
                      <GameHeader imgDropdown={imgDropdown} />
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/p2p" }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {t("page-header.p2p")}
                </NavLink>
                <div
                  className="dropdown-menu-list p2p-dropdown"
                  style={{ backgroundImage: `url(${bgImg})` }}
                >
                  <div className="x-container-no-m navbar-expand">
                    <div className="row g-2">
                      <P2PHeader imgDropdown={imgDropdown} />
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/lottery" }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {t("page-header.lottery")}
                </NavLink>
                <div
                  className="dropdown-menu-list lottery-dropdown"
                  style={{ backgroundImage: `url(${bgImg})` }}
                >
                  <div className="x-container-no-m navbar-expand">
                    <div className="row g-2 justify-content-around">
                      <LotteryHeader imgDropdown={imgDropdown} />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav">
              {/* <li className="nav-item">
                <NavLink
                  to={{ pathname: "/sponsor" }}
                  className="nav-link icon"
                  activeClassName="active"
                >
                  <img src={icons["sponsor-icon.svg"]} alt="sponsor" />
                  {t("page-header.sponsor")}
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/promos" }}
                  className="nav-link icon"
                  activeClassName="active"
                >
                  {this.state.currentEvent === "christmas" ? (
                    <img src={christmas["promos.webp"]} alt="promos" />
                  ) : (
                    <img src={icons["promos.svg"]} alt="promos" />
                  )}
                  {t("page-header.promos")}
                </NavLink>
                  {/* <div
                    className="dropdown-menu-list promos-dropdown"
                    style={{ backgroundImage: `url(${bgImg})` }}
                  >
                    <div className="x-container-no-m navbar-expand">
                      <div className="row g-2 justify-content-around">
                        {this.state.promos &&
                          this.state.promos.map((item, index) => {
                            return (
                                <Link
                                    to={{
                                      pathname: "/promos-detail/" + item.promotionId,
                                      params: { promo_id: item.promotionId },
                                    }}
                                      className="col-3 position-relative"
                                      key={index}
                                    >
                                      <div className="dropdown-title">
                                        <p className="w-100">{item.promotionName}</p>
                                      </div>
                                      <div className="more-btn-blue mt-1">
                                        {t("global.enter")}
                                      </div>
                                      <img
                                        className="dropdown-img"
                                        src={item.promotionImage}
                                        alt="Dropdown"
                                      />
                                    </Link>
                                  );
                                })}
                            </div>
                          </div>
                      </div> */}
                 
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/vip" }}
                  className="nav-link icon"
                  activeClassName="active"
                >
                  {this.state.currentEvent === "christmas" ? (
                    <img src={christmas["vip.webp"]} alt="vip" />
                  ) : (
                    <img src={icons["vip.svg"]} alt="vip" />
                  )}
                  {t("vip.vip")}
                </NavLink>
              </li>
              {this.state.login ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/deposit" }}
                      className="nav-link icon"
                      activeClassName="active"
                    >
                      <img src={icons["deposit.svg"]} alt="deposit" />
                      {t("page-header.deposit")}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/withdrawal" }}
                      className="nav-link icon"
                      activeClassName="active"
                    >
                      <img src={icons["withdrawal.svg"]} alt="withdrawal" />
                      {t("page-header.withdraw")}
                    </NavLink>
                  </li>
                </>
              ) : (
                <></>
              )}
            </ul>
            <ul className="navbar-nav">
              {this.state.login ? (
                <li className="nav-item">
                  <div
                    className="d-flex align-items-center justify-content-between position-relative"
                    onMouseEnter={() => {
                      this.setState({ profileDropdwon: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ profileDropdwon: false });
                    }}
                  >
                    <div className="user-balance-container pe-1">
                      <span className="text-end">
                        {this.props.currentUser.data.username}
                      </span>
                      <div className="user-balance">
                        <div className="coin-balance">
                          <img src={coin["coin.svg"]} alt="coin" />
                          <span>
                            {Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }).format(
                              (topProfileData != null &&
                                topProfileData.topProfile.coin) ||
                                0
                            )}
                          </span>
                        </div>
                        <p className="px-1">
                          {this.props.currentUser.data.currency}{" "}
                          {new Intl.NumberFormat("en-US", num_format).format(
                            (topProfileData &&
                              topProfileData.topProfile &&
                              topProfileData.topProfile.balance) ||
                              0.0
                          )}
                        </p>
                        <img
                          className={`header-arrow ${
                            this.state.profileDropdwon ? "active" : ""
                          }`}
                          src={img["arrow.png"]}
                          alt="arrow"
                        />
                      </div>
                      <ul
                        className={`profile-dropdown ${
                          this.state.profileDropdwon ? "active" : ""
                        }`}
                      >
                        <li>
                          <NavLink to={{ pathname: "/personal-information" }}>
                            <img
                              src={icons["personal.svg"]}
                              alt="personal-information"
                            />
                            <p>{t("page-header.personal-information")}</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={{ pathname: "/vip-exclusive" }}>
                            <img
                              src={icons["vip-exclusive.svg"]}
                              alt="vip-exclusive"
                            />
                            <p>{t("page-header.vip-exclusive")}</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={{ pathname: "/transaction-history" }}>
                            <img
                              src={icons["transaction.svg"]}
                              alt="transaction-history"
                            />
                            <p>{t("page-header.transaction-history")}</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={{ pathname: "/betting-history" }}>
                            <img
                              src={icons["betting.svg"]}
                              alt="betting-history"
                            />
                            <p>{t("page-header.betting-history")}</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={{ pathname: "/messages" }}>
                            <img src={icons["message.svg"]} alt="messages" />
                            <p>{t("message.message")}</p>
                            {(() => {
                              if (!this.state.loading) {
                                if (this.state.unreadMsgCnt > 0) {
                                  return (
                                    <div className="badge">
                                      {this.state.unreadMsgCnt}
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            })()}
                          </NavLink>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="btn btn-outline-white mx-auto"
                            onClick={this.handleSignOutHandler}
                          >
                            {t("page-header.sign-out")}
                          </button>
                        </li>
                      </ul>

                      <ul
                        className={`profile-dropdown ${
                          this.state.profileDropdwon ? "active" : ""
                        }`}
                      >
                        <li>
                          <NavLink to={{ pathname: "/personal-information" }}>
                            <img
                              src={icons["personal.svg"]}
                              alt="personal-information"
                            />
                            <p>{t("page-header.personal-information")}</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={{ pathname: "/vip-exclusive" }}>
                            <img
                              src={icons["vip-exclusive.svg"]}
                              alt="vip-exclusive"
                            />
                            <p>{t("page-header.vip-exclusive")}</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={{ pathname: "/transaction-history" }}>
                            <img
                              src={icons["transaction.svg"]}
                              alt="transaction-history"
                            />
                            <p>{t("page-header.transaction-history")}</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={{ pathname: "/betting-history" }}>
                            <img
                              src={icons["betting.svg"]}
                              alt="betting-history"
                            />
                            <p>{t("page-header.betting-history")}</p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={{ pathname: "/messages" }}>
                            <img src={icons["message.svg"]} alt="messages" />
                            <p>{t("message.message")}</p>
                            {(() => {
                              if (!this.state.loading) {
                                if (this.state.unreadMsgCnt > 0) {
                                  return (
                                    <div className="badge">
                                      {this.state.unreadMsgCnt}
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            })()}
                          </NavLink>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="btn btn-outline-white mx-auto"
                            onClick={this.handleSignOutHandler}
                          >
                            {t("page-header.sign-out")}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="user-profile-container">
                      {topProfileData ? (
                        <img
                          className="user-profile-img"
                          src={
                            (topProfileData &&
                              topProfileData.topProfile.profile_picture) ||
                            avatar["male-7.jpg"]
                          }
                          alt="profile img"
                        />
                      ) : (
                        <div className="react-loading">
                          <ReactLoading
                            type={"spinningBubbles"}
                            color={"#fff"}
                          />
                        </div>
                      )}
                      {(() => {
                        if (this.state.unreadMsgCnt > 0) {
                          return <span className="notify-red-dot" />;
                        }
                      })()}
                      <p className="vip-icon">
                        {t("page-header.vip")}{" "}
                        {this.props.currentUser.data.user_group_level}
                      </p>
                      {this.state.currentEvent === "christmas" ? (
                        <img
                          className="avatar-border-img"
                          src={christmas["avatar-christmas.webp"]}
                          alt="avatar-border"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </li>
              ) : (
                <>
                  <li className="nav-item">
                    <NavLink
                      to={{ pathname: "/login" }}
                      className="btn btn-blue"
                    >
                      {t("page-header.log-in")}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={{
                        pathname: sessionStorage.getItem("google_user_info")
                          ? "/registerSocialMedia"
                          : "/register",
                      }}
                      className="btn btn-white"
                    >
                      {t("page-header.join-now")}
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    currentUser: state.currentUser,
    logout_data: auth.logout_data,
    unreadMessagesCnt: state.inbox.count_unread_inbox_alert_data,
    featured_promos_data: state.home.featured_promos_data,
    topProfileData: state.profile.top_profile_data,
    login_data: auth.login_data,
    social_media_user_verification_result:
      state.profile.social_media_user_verification_result,
  };
};

const mapDispatchToProps = {
  getGameVendorList,
  postLogOut,
  getCountUnreadInboxAlert,
  getFeaturedPromotion,
  getTopProfile,
  postLogin,
  getUserData,
  socialMediaUserVerification,
  updateFingerPrint,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withNamespaces("translation")(PageHeader)));
