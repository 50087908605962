import React, { Component } from "react";
// import MobileLiveCasino from "./MobileLiveCasino";
import MobileHome from "../Home/MobileHome";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import * as actions from "../../store/ducks/liveCasino/actions";
import * as currentUserActions from "../../store/ducks/currentUser/actions";
import moment from "moment-timezone";
import Swal from "sweetalert2";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isDesktop } from 'react-device-detect';
import { getTopProfile, updateMemberPopUp } from "../../store/ducks/profile/actions";

//Components
import GameContent from "../../components/GamePageContent/GamePageContent";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import { liveCasinotSeo } from "../../components/SeoMeta/SeoMeta";


export class Sport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
      activeBtn: 0,
      triggerAnimation: true,
      loading: true,
      vendorList: [],
    };
  }

  componentDidMount() {
    // console.log("[componentDidMount]");
    let params = {};
    this.props.getVendorList(params);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]",nextProps,nextProps.rs);
    if (nextProps.vendor !== undefined && nextProps.vendor !== this.props.vendor) {
      if (nextProps.vendor.responseCode === 200 && nextProps.vendor.msgType === "success") {
        this.setState({
          loading: false,
          vendorList: nextProps.vendor.data.vendor_list
        });
      }
    }

    // still using?
    if (nextProps.rs !== undefined && nextProps.rs !== this.props.rs) {
      // console.log("running nextProps.rs");
      if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === "success") {
        if (nextProps.liveCasino.actionFrom === "PageContent") {
          Swal.close();
          if (nextProps.rs.data.hasOwnProperty("url")) {
            window.open(nextProps.rs.data.url);
          } else if (nextProps.rs.data.hasOwnProperty("gameError")) {
            this.gameErrorResponse(nextProps.rs.data.gameError);
          }
        }
      } else {
        const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title: t("global.failed"),
          icon: "error",
          html: `${t("global.error")} (${nextProps.rs.message[0]}). ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon",
          } : [],
        });
      }
    }
    return true;
  }

  gameErrorResponse = (response) => {
    const { t } = this.props;

    switch (response.status) {
      case 1:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
          title: t("global.reminder"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.kindly-refresh-the-page")}`,
          showConfirmButton: true,
          confirmButtonText: `${t("global.refresh")}`,
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon",
          } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
      case 2:
        const currentUserTimeZone = this.props.currentUser.data.time_zone;
        const startTime = moment(response.maintenance_start_time)
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
        const endTime = moment(response.maintenance_end_time)
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
        const timeZone = moment()
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("[(GMT] z[)]")
          .toString();
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:exclamation.svg?color=%23ffffff">' : "",
          icon: "info",
          titleText: `${t("global.under-maintenance")}`,
          html: `${t("global.from")} <b>${startTime}</b> ${t("global.to")} <b>${endTime}</b> ${timeZone}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon",
          } : [],
        });
        break;
      case 3:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title: t("global.failed"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon",
          } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
      default:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ffffff">' : "",
          title: t("global.failed"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: 500 - ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon",
          } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
    }
  };

  accessGame = (game) => {
    if (!localStorage.getItem("accessToken")) {
      const { t } = this.props;
      Swal.fire({
        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
        icon: "info",
        title: t("global.sorry"),
        text: t("global.login-proceed"),
        confirmButtonText: t("global.ok"),
        showCancelButton: (!isDesktop) ? false : '',
        cancelButtonText: t("global.cancel"),
        customClass: (!isDesktop) ? {
          container: "swal-mobile-container sorry",
          icon: "swal-sorry-icon",
        } : []
      });
    } else {
      localStorage.setItem("gameLauncher", 'casino|' + game);
      window.open('/game-launcher');
    }
  };

  changeActivePage = (num) => {
    this.removeAnimation(num);
    setTimeout(() => {
      this.setState({ triggerAnimation: true, activePage: num, });
    }, 1000);
  };

  removeAnimation = (num) => {
    this.setState({
      triggerAnimation: false,
      activeBtn: num,
    });
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/live-casino",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const icon = this.imageImport(
      require.context(
        "../../assets/images/icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    let icons = this.state.vendorList ? this.state.vendorList.map((val, idx) => {
      return {
        id: idx,
        activeImg: icon[`${val.game_vendor_slug}-icon-active.svg`],
        img: icon[`${val.game_vendor_slug}-icon.svg`],
        hotStatus: val.hot_status
      };
    }) : [];
    let liveCasino = this.state.vendorList ? this.state.vendorList.map((val, idx) => {
      let content = "";
      let asset1 = "";
      let asset2 = "";
      let container = "";
      switch (val.game_vendor_slug) {
        case "pt":
          content = `${t("live-casino.pt-content")}`;
          asset1 = "gp-coin.webp";
          asset2 = "dm-img.webp";
          break;
        case "sb":
          break;
        case "ttg":
          break;
        case "dg":
          content = `${t("live-casino.dg-content")}`;
          asset1 = "spade-img.webp";
          asset2 = "dg-coin.webp";
          break;
        case "pp":
          content = `${t("live-casino.pp-content")}`;
          asset1 = "clover-img.webp";
          asset2 = "dice-img.webp";
          break;
        case "qt":
          break;
        case "sa":
          content = `${t("live-casino.sa-content")}`;
          asset1 = "gp-coin.webp";
          asset2 = "dm-img.webp";
          break;
        case "gd":
          break;
        case "ag":
          content = `${t("live-casino.ag-content")}`;
          asset1 = "dice-img.webp";
          asset2 = "coin-img.webp";
          break;
        case "ea":
          content = `${t("live-casino.ea-content")}`;
          asset1 = "dm-img.webp";
          asset2 = "ea-coin.webp";
          break;
        case "isb":
          break;
        case "sp":
          break;
        case "gp":
          content = `${t("live-casino.gpi-content")}`;
          asset1 = "spade-img.webp";
          asset2 = "gp-coin.webp";
          break;
        case "sbo":
          content = `${t("live-casino.sbo-content")}`;
          asset1 = "gp-coin.webp";
          asset2 = "dm-img.webp";
          container = "st";
          break;
        case "evo":
          content = `${t("live-casino.evo-content")}`;
          asset1 = "gp-coin.webp";
          asset2 = "dm-img.webp";
          container = "eg";
          break;
        case "mg":
          content = `${t("live-casino.mg-content")}`;
          asset1 = "gp-coin.webp";
          asset2 = "dm-img.webp";
          break;
        case "sg":
          break;
        case "ab":
          content = `${t("live-casino.ab-content")}`;
          asset1 = "gp-coin.webp";
          asset2 = "dm-img.webp";
          break;
        default:
          break;
      }

      let imageLanguage = '';
      switch (this.props.currentUser.data.language_code) {
        case "zh-CN":
          imageLanguage = "-cn"
          break;
        // case "ms":
        //   imageLanguage="-ms" 
        //   break;
        default:
          imageLanguage = "-en"
          break;
      }
      return (
        <>
          <div className="col-5">
            <GameContent
              pageTitle={img[`${val.game_vendor_slug}-title${imageLanguage}.webp`]}
              content={content}
              contentImg={img["content-img.webp"]}
              gameIcon={icons}
              link={"/"}
              fadeIn={this.state.triggerAnimation}
              activeBtn={this.state.activeBtn}
              changeActivePage={this.changeActivePage}
              accessGame={this.accessGame}
              nolink={true}
              curVendor={val.game_vendor_slug}
            />
          </div>
          <div className="col-7 position-relative">
            <div className={`lc-${val.game_vendor_slug === "evo" || val.game_vendor_slug === "sbo" ? container : val.game_vendor_slug}-container ${this.state.triggerAnimation === true ? "fade-in-right" : "fade-out-right"}`}>
              <img className="asset-1" src={img[`${asset1}`]} alt={asset1.substring(0, asset1.indexOf(".") - 1)} />
              <img className="asset-2" src={img[`${asset2}`]} alt={asset2.substring(0, asset2.indexOf(".") - 1)} />
              <img className="main-asset" key={img[`${val.game_vendor_slug}-img.webp`]} src={img[`${val.game_vendor_slug}-img.webp`]} alt={`${val.game_vendor_slug}-img`} />
              {/* <LazyLoadImage className="main-asset" key={img[`${val.game_vendor_slug}-img.png`]} src={img[`${val.game_vendor_slug}-img.png`]} alt={`${val.game_vendor_slug}-img`} effect='blur' /> */}
            </div>
          </div>
        </>
      );
    }) : [];
    let liveCasinoMobile = this.state.vendorList ? this.state.vendorList.map((val, idx) => {
      return {
        name: val.game_vendor_title,
        slug: val.game_vendor_slug,
        img: `${val.game_type}-${val.game_vendor_slug}-img.webp`,
        gameType: val.game_type,
        hotStatus: val.hot_status
      }
    }) : [];

    let topProfileData = null;
    let backupUrlLoading = true;
    let showBackUpUrl = false;

    if (this.props.topProfileData) {
      backupUrlLoading = false;
      topProfileData = this.props.topProfileData && this.props.topProfileData.data;
      showBackUpUrl = (topProfileData.topProfile.show_pop_up_url) === true ? true : false;
    }

    return (
      <Auxiliary>
        {liveCasinotSeo}
        {this.state.loading && <LoadingPage />}
        {
          isDesktop ? (
            <section
              className="provider-games-section"
              style={{ backgroundImage: `url(${img["lc-bg.webp"]}` }}
            >
              <div className="x-container-no-m lc-game-container">
                <div className="row">
                  {this.state.loading ? (
                    <></>
                  ) : (
                    <>
                      {liveCasino[this.state.activePage]}
                    </>
                  )}
                </div>
              </div>
            </section>
          ) : (
            <MobileHome
              vendorList={liveCasinoMobile}
              location={this.props.location}

              currentUser={this.props.currentUser.data}
              updateMemberPopUp={this.props.updateMemberPopUp}
              topProfileData={topProfileData}
              backupUrlLoading={backupUrlLoading}
              showBackUpUrl={showBackUpUrl}
            />
          )
        }

      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  // console.log("[mapStateToProps]",state);
  return {
    vendor: state.liveCasino.vendor,
    liveCasino: state.liveCasino,
    rs: state.liveCasino.rs,
    currentUser: state.currentUser,
    topProfileData: state.profile.top_profile_data
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getVendorList: (params) => dispatch(actions.getVendorList(params)),
    accessGame: (params, vendor) => dispatch(actions.accessGame(params, vendor)),
    setGameQT: (params) => dispatch(currentUserActions.setGameQT(params)),
    getTopProfile,
    updateMemberPopUp: (params) => dispatch(updateMemberPopUp(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Sport));
